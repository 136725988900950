import {
  Grid,
  GridItem,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import React from 'react';
import './CompanyInfo.css';
import Linkify from 'react-linkify';
import RoundedLabel from './RoundedLabel';

function CompanyInfo({companydata}) {
  companydata = companydata ? companydata['fields'] : {}
  var companyName = companydata['Company Name']
  var requirements = companydata['Requirements (Consolidated)'] ? companydata['Requirements (Consolidated)'] : [];
  var applyLink = companydata['Signup Link (Combined)'] ? companydata['Signup Link (Combined)'] + "?ref=sidehustlestack" : "";
  applyLink = applyLink.includes("http") ? applyLink : "https://" + applyLink

  function renderRequirements(){
    return (
      <div className='requirements'>
        {
          requirements.map((r) => {
            if (r in companydata) {
              if (companydata[r] === "Yes"){
                return <RoundedLabel text={r} />
              } else if (companydata[r] !== "No") {
                return <RoundedLabel text={r + ": " + companydata[r]} />
              }
            }
            return <RoundedLabel text={r} />
          })
        }
        {companydata['Additional Requirements'] ? <div className='other-req'><div className='other-req-header'>Other: </div> <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
            </a>
        )}
        >{companydata['Additional Requirements']}</Linkify></div> : ""}
      </div>
    )
  }

  function renderPricing() {
    var ret = "no pricing data";
    switch (companydata["Pricing"]) {
      case "Free": ret = "Free"; break;
      case "Percentage Take Rate": ret = companydata['Take Rate'] ? companydata['Take Rate'] + "% Take Rate" : "no pricing data"; break;
      case "Other": ret = companydata["Other Pricing"] ? <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
          </a>
      )}
      >{companydata["Other Pricing"]}</Linkify> : "no pricing data"; break;
      default:
        ret = "no pricing data"
    }
    return ret;
  }

  function renderAvgEarnings() {
    if (companydata["Earnings"]) {
      return (
          <div className='data-vis'>
            <div className='data-vis-title'>AVERAGE EARNINGS</div>
            <div className='data-vis-avg-earnings'>
              <h1>${companydata["Earnings"]}</h1>
              <p>/MONTH</p>
            </div>
          </div>
      )
    }
  }
  
  function renderDistribution() {
    if (companydata['Distribution']) {
      let d_arr = companydata['Distribution'].split(", ")
      return(
          <div className='data-vis'>
            <div className='data-vis-title'>DISTRIBUTION</div>
            <div className="distribution-container">
              <div className="distribution-container-info">
                <div className="column">
                  <div className="marker">{d_arr[0]}<span className='marker-percent'>%</span></div>
                </div>
                <div className="column">
                  <div className="marker">{d_arr[1]}<span className='marker-percent'>%</span></div>
                </div>
                <div className="column">
                  <div className="marker">{d_arr[2]}<span className='marker-percent'>%</span></div>
                </div>
                <div className="column">
                  <div className="marker">{d_arr[3]}<span className='marker-percent'>%</span></div>
                </div>
              </div>
              <div className="distribution-container-labels">
                <div className="temp-text">$1k</div>
                <div className="temp-text">$2.5k</div>
                <div className="temp-text">$5k</div>
              </div>
            </div>
          </div>
      )
    }
  }

  return (
    <div className='company-info'>
      <Grid
        templateRows='repeat(3)'
        templateColumns='repeat(2, 1fr)'
        gap={4}
      >
        <GridItem rowSpan={1} colSpan={1} className="info-container">
          <a className="company-name" href={companydata['Website']} target="_blank" without rel="noreferrer">{companyName}</a>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} >
          <div className="apply-link">
            <a href={applyLink} target="_blank" without rel="noreferrer"><RoundedLabel text="Sign up ↗"/> </a>
          </div>
        </GridItem>
        <GridItem colSpan={2}>
          <Wrap>
            <WrapItem>
              {renderAvgEarnings()}
            </WrapItem>
            <WrapItem>
              {renderDistribution()}
            </WrapItem>
          </Wrap>
        </GridItem>
        <GridItem rowSpan={1} colSpan={2} >
          <div className="info-container">
            <h5>Description</h5>
            {companydata['Description (Consolidated)'] ? <p><Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                </a>
            )}>
              {companydata['Description (Consolidated)']}
            </Linkify></p> : <p>No description</p>}
          </div>
          <div className="info-container">
            <h5>Founded</h5>
            {companydata['Founded'] ? <p>{companydata['Founded']}</p> : <p>No data</p>}
          </div>
          <div className="info-container">
            <h5>Requirements</h5>
            {renderRequirements()}
          </div>
          <div className="info-container">
            <h5>Pricing</h5>
            {renderPricing()}
          </div>
        </GridItem>
      </Grid>
    </div>
  );
}

export default CompanyInfo;
