import React from 'react';
import {
  Box,
} from '@chakra-ui/react';
import "./RoundedLabel.css";

function RoundedLabel({text, color="#4DA850", evergreen=false}) {
  var labelStyle = {
    margin: '0px 2.5px',
    fontSize: '11px',
    fontWeight: 'bold',
    paddingLeft: '10px',
    paddingRight: '10px',
    color: 'white',
    display: 'inline-block',
    textAlign: 'center'
  };
  if (evergreen) {
    labelStyle['float'] = 'right';
    return <Box style={labelStyle} bg={color} rounded='20px' p='1'><div className="symbol">🌲</div> Evergreen</Box>
  }
  return <Box style={labelStyle} bg={color} rounded='20px' p='1'>{text}</Box>
}

export default RoundedLabel;
