import React from 'react';
import "./CompanyCard.css";
import { Image, Text, Stack, CardBody, Card } from '@chakra-ui/react'
import RoundedLabel from './RoundedLabel';

function CompanyCard(props) {
  // console.log(props)
  const companydata = props.data.fields;
  const workType = companydata["Work Type"] ? companydata["Work Type"] : [];
  const companyName = companydata["Company Name"]
  const logoUrl = companydata["Logo (Combined)"]
  const description = companydata["Description (Consolidated)"];
  const evergreen = companydata["Evergreen? (Anyone can do)"] === "Yes" ? true : false;
  
  const evergreenColor = "#a6b959"; 
  
  return (
    <Card maxW='sm' variant="none" >
      <CardBody>
      {evergreen ? <RoundedLabel evergreen={true} color={evergreenColor} /> : null}
      {logoUrl ? <Image variant="top" src={logoUrl[0].url} alt="logo" borderRadius='lg' /> : <p>No logo</p>}
      
      {workType.map(w => {
        return <RoundedLabel text={w} />
      })}      
        <Stack mt='3'>
          <Text fontSize={20}>{companyName}</Text>
          <Text className="description" fontSize={15}>{description}</Text>  
        </Stack>
      </CardBody>
    </Card>
  );
}

export default CompanyCard;
