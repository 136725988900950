import React from 'react';
import CompanyCard from './CompanyCard';
import {
    Grid
  } from '@chakra-ui/react'
  import { NavLink } from "react-router-dom";

export default function DataGrid({companyorder, data, filter}){  
    if (companyorder.length === 0) {
        return (<div class="loader"><div class="lds-heart"><div></div></div></div>)
    } else {
        return (
        <div className='fade-in'>
            <Grid className="datagrid" templateColumns='repeat(4, 1fr)' gap={6}>
            {
                companyorder.map((r) => {
                    if (filter === "" || data[r]['fields']['Site Filters'].includes(filter)){
                        return <NavLink to={'/company/'+r} >
                            <CompanyCard data={data[r]}/> 
                        </NavLink>
                    } 
                    return <div style={{display: 'none'}} ></div>
                })
            }
            </Grid>
            </div>
        )
    }
}