import React from "react";
import Navbar from 'react-bootstrap/Navbar'
import { Box, Image, Link as ChakraLink } from '@chakra-ui/react';
import { Heading, Text } from '@chakra-ui/react';
import logo from './images/shs_logo.png';
import { Link as RouterLink } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import ReactGA from 'react-ga4';

const About = () => {
    // handle Newsletter link click
    const handleNewsletterClick = () => {
      ReactGA.event({
        category: 'Links',
        action: 'Newsletter',
        label: 'Newletter clicked!',
        value: 1
      });
    }

  return (
    <div>
      <div className="Home">
      <div className="background">
        <Navbar>
            <div class="container px-5">
                <NavLink class="navbar-brand" to="/"><Image boxSize="50px" src={logo} /></NavLink>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                      <RouterLink class="nav-link" to="/about" end>
                        About
                      </RouterLink> 
                      <a class="nav-link" onClick={() => handleNewsletterClick()}  target="__blank" without rel="noreferrer" href='https://sidehustlestack.substack.com/' end>
                        Newsletter ↗
                      </a> 
                    </ul>
                </div>
            </div>
        </Navbar>
        <Box as={"header"} position="relative" height="300px">
            <div class="container px-5">
                <div class="row gx-5 justify-content-center">
                    <div class="col-lg-8">
                        <div class="text-left my-5">
                            <h1 class="display-6 fw-bolder text-white mb-2">Side Hustle Stack is the ultimate database of earning opportunities.</h1>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
        {/* Add the new div with the headline and subtext */}
      
        </div>
        <Box
          class="child"
          position="absolute"
          display="flex" // Add display flex
          justifyContent="center" // Center horizontally
          mt="-6rem" // Add padding to the bottom for displacement
        >
          <Box
            borderWidth="1px"
            borderColor="black"
            //width="calc(100% - 30rem)" // Adjust the width to match the other container
            width={["calc(100% - 2rem)", "calc(100% - 4rem)",  "calc(100% - 4rem)", "calc(100% - 30rem)"]} // Adjust the width to match the other container based on screen size
            p={5}
            backgroundColor="#F8FFF6"
            borderRadius="6px"
            padding={["20px", "30px", "40px"]} // Adjust padding based on screen size
            //padding="40px"
            marginBottom={["20px", "40px"]} // Add more bottom padding on mobile; use 40px for larger screens 
          >
            <Heading as="h2" size="md" mb={3}>
              A resource that helps you earn more by making better decisions.
            </Heading>
            <Text>
              Side Hustle Stack was built to allow anyone to find better opportunities and get paid for doing work that they enjoy. We want to become the site to not only look for work but also get an x-ray look at platforms before signing up for something you might not like; a resource you can visit to level up your earnings.
            </Text>
            <Text mt={3} mb={3}>To drop us feedback, suggestions, or say hi, <ChakraLink href="mailto:hello@sidehustlestack.co" >reach out! 💌 </ChakraLink></Text>
            <Text as="i"> Side Hustle Stack is a labor of love built and maintained by <a target="__blank" without rel="noreferrer" href='https://twitter.com/ljin18'>Li</a>, <a target="__blank" without rel="noreferrer" href='https://twitter.com/lilashroff'>Lila</a>, <a target="__blank" without rel="noreferrer" href='https://twitter.com/megnung'>Megan</a>, <a target="__blank" without rel="noreferrer" href='https://twitter.com/barofclo'>Chloe</a>, and <a target="__blank" without rel="noreferrer" href='https://twitter.com/brndnwll'>Brandon</a></Text>
                       
          </Box>
        </Box>
</div>

    </div>
  );
};
  
export default About;