import {
  Box
} from '@chakra-ui/react';
import React from 'react';

function Banner({bannerHTML}) {
  

  return (
    <Box
          class="child"
          display="flex" // Add display flex
          justifyContent="center" // Center horizontally
          mt="1rem" // Add padding to the bottom for displacement
        >
          <Box
            className='subscribe-box'
            textAlign="center"
            borderWidth="1px"
            borderColor="black"
            width={["calc(90% - 1rem)", "calc(90% - 2rem)",  "calc(90% - 2rem)", "calc(90% - 15rem)"]} // Adjust the width to match the other container based on screen size
            p={8}
            backgroundColor="white"
            borderRadius="6px"
            padding={["5px", "10px", "15px"]} // Adjust padding based on screen size
            marginBottom={["20px", "40px"]} // Add more bottom padding on mobile; use 40px for larger screens 
          >
            <p dangerouslySetInnerHTML={{__html: bannerHTML}}></p>
         </Box>
        </Box>
  );
}

export default Banner;
