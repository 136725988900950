import React from "react";
import "./styles.css";

const Footer = () => {
  return (
    <div className="footer">
      {/* Footer removed here */}
    </div>
  );
};
  
export default Footer;