import React, { useEffect, useState } from 'react';
import "./styles.css";
import Home from './Home';
import About from './About';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import CompanyModal from './CompanyModal';
import ReactGA from 'react-ga4';

const topN = 8 // assumes that there are less than N values Yes in column Bump

const shuffleArray = (array, bumparr) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    if (bumparr.includes(array[i]) || bumparr.includes(array[j])) {
      continue;
    }
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array
}
function App() {
  const [data, setData] = useState({})
  const [companyorder, setCompanyOrder] = useState([])

  useEffect(() => {
    ReactGA.initialize('G-JPEP7LHYR2');
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

    const processRawData = array => {
      // save a dict where key is company name and shuffled array of co names for ordering
      const dd = {};
      const namearr = [];
      var bumparr = []
      for (let i = 0; i < array.length; i++) {
        var co_name = array[i]['fields']['Company Name'].toLowerCase();
        dd[co_name]  = array[i]; 
        if (array[i]['fields']['Bump']) {
          const j = Math.floor(Math.random() * (topN));
          namearr.splice(j, 0, co_name)
          bumparr.push(co_name)
        } else {
          namearr.push(co_name)
        }
      }
      let co_order = shuffleArray(namearr, bumparr);
      setCompanyOrder(co_order)
      setData(dd)

    }
    if (Object.keys(data).length === 0) {
        fetch('https://api.airtable.com/v0/apprtLLxgrImhJlBp/Platform%20Submission?view=First%20Platforms', {
          headers: {
            'Authorization': 'Bearer ' + process.env.REACT_APP_AIRTABLE_API_KEY
          }
        })
        .then((resp) => resp.json())
        .then(data => {
          // console.log(data)
          processRawData(data.records)
        }).catch(err => {
          // Error :(
        });
    }
  }, [data])
  return (
    <BrowserRouter>
      <Routes >
        <Route path="/" element={<Home data={data} companyorder={companyorder}/>} >
          <Route path="company/:co" element={<CompanyModal data={data} />} />
        </Route>
        <Route path="/about" element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
