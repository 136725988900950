import React, { useEffect} from 'react';
import CompanyInfo from './CompanyInfo';
import {
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    Button
  } from '@chakra-ui/react'
  import { useNavigate, useParams } from "react-router-dom";

export default function CompanyModal({data}){
  let params = useParams();
  let co = params.co.toLowerCase();
  const navigate = useNavigate();

  
  const closeModal = (e) => {
    if (e) {
      e.stopPropagation();
    }
    navigate("/");
  };


  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);
  const { isOpen } = useDisclosure({defaultIsOpen: true} )
  if (Object.keys(data).length === 0) {
    return <div></div>
  } else {
    return (
        <Modal isOpen={isOpen} onClose={closeModal} size="xl">
          <ModalOverlay />
          <ModalContent>
          <ModalBody>
            <CompanyInfo companydata={data[co]}/>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='green' mr={3} onClick={closeModal}>
              Close
            </Button>
          </ModalFooter>
          </ModalContent>
        </Modal>
    )
  }
}